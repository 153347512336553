import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState, CartItem } from '../store'
import clsx from 'clsx';
import { Colors } from "../colors";
import EmptyItem from "../component/Empty";
import { addToCart, addTwoPriceItem, addCategory } from "../redux";
import {
    Grid,
    Card,
    CardMedia,
    CardActions,
    CardContent,
    Avatar,
    Paper,
    IconButton,
    CssBaseline,
    Typography,
    makeStyles,
    Slide,
    CircularProgress
} from "@material-ui/core";
import {
    AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon,
    ShopTwo as ShopTwoIcon, RemoveShoppingCart as RemoveShoppingCartIcon
} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({

    //---------------New Css---------//

    root: {
        boxSizing: 'border-box',
        flexGrow: 1,
    },
    main: {
        paddingTop: theme.spacing(1),
        paddingBottom: 60,
        backgroundColor: '#e3e3e3',
        boxSizing: 'border-box',
        minHeight: '100vh',

        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(2),
        },

        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(5)
        },



    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        background: Colors.primary,
        margin: '0 auto',
        marginBottom: 10

    },
    content: {
        display: 'flex',
        boxSizing: 'border-box',
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }

    },
    menuGrid: {
        width: 295,
        overflowX: 'hidden',
        paddingBottom: theme.spacing(1),
        overflowY: 'auto',
        height: 'calc(100vh - 152px)',
        maxHeight: '100%',
        marginRight: 15,
        position: 'fixed',
        left: 20,
        top: 85,
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },


    },

    menuItemContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        margin: '0 auto',
        maxWidth: '95%',

    },
    menuItem: {
        width: 133,
        height: 120,
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        background: 'white',
        borderRadius: 5,
        overflow: 'hidden',
        cursor: 'pointer',
        '&:hover': {
            border: '2px solid gray'
        }

    },
    menuBorder: {
        border: '2px solid gray',
        borderRadius: 7,

    },
    listGrid: {
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        marginTop: 10,
        boxSizing: 'border-box',
        paddingLeft: '27%',
        [theme.breakpoints.down('md')]: {
            padding: 20
        },

        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },

    },
    cardGrid: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 7,
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out 0s',
        border: '1px solid rgb(243,243,243)',
        '&:hover': {
            transform: 'translateY(-6px)',
            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
        }
    },

    cardMediaRoot: {
        height: 230,
        padding: 20,
        boxSizing: 'border-box'
    },
    cardMediaImg: {
        width: '100%',
        maxHeight: '100%',
        textAlign: 'center',
        height: '100%',
        '& > img': {
            width: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            height: 'auto',
            borderRadius: 4,
            objectFit: 'contain',
        }
    },
    cardContent: {
        flexGrow: 1
    },

    mobileListGrid: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: theme.spacing(10),
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    mobileListPaper: {
        borderRadius: 15,
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        marginTop: theme.spacing(1),
        padding: 10,
    },
    clickPaper: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: 'auto',
        padding: 15
    },
    mobileListContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,

    },
    setContent: {
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    mobileListImg: {
        maxWidth: '30%',
        marginRight: theme.spacing(1),
        '& > img': {
            maxWidth: 80,
            height: 80
        }
    },
    setImg: {
        maxWidth: '100%',
        marginBottom: 20,
        '& > img': {
            width: '100%',
            maxWidth: '100%',
            height: 'auto'
        }
    },
    mobileListAction: {
        maxWidth: '13%'
    },
    setAction: {
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
}))

export default function C() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory()
    const { ItemState, CategoryState } = useSelector(state => state) as AppState
    const [currentItem, setCurrentItem] = useState(-1);
    const [click, setClick] = useState(false);
    const [loadingIndex, setloadingIndex] = useState(-1)
    const [animation, setAnimation] = useState(false)
    const handleClick = (key: number) => {
        setCurrentItem(key);
        setClick(!click);
    }
    const goToDetail = (productId: string) => {
        history.push(`/detail/${productId}`)
    }
    useEffect(() => {
        setAnimation(true)
    }, [setAnimation])
    const addToCartHandler = (item: CartItem) => {
        dispatch(addToCart({ ...item, qty: 1 }))
        setloadingIndex(item.key)
        setTimeout(() => {
            setloadingIndex(-1)
        }, 1000);
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.main}>
                <div className={classes.content}>
                    <div className={classes.menuGrid}>
                        <div className={classes.menuItemContainer}>
                            {

                                CategoryState.categories.map(c => {
                                    return (
                                        <Slide in={animation} direction='right' timeout={500}>
                                            <div className={clsx(classes.menuItem, ItemState.categoryId === c.id && classes.menuBorder)}
                                                onClick={() => dispatch(addCategory(c.id))}
                                            >
                                                <Avatar className={classes.small}>{c.name.charAt(0).toLocaleUpperCase()}</Avatar>
                                                <Typography variant='subtitle2' align='center'>
                                                    {c.name}
                                                </Typography>
                                            </div>
                                        </Slide>
                                    )
                                })
                            }


                        </div>
                    </div>
                    {
                        ItemState.items.length < 1 ?
                            <div className={classes.listGrid}><EmptyItem /></div> :
                            <div className={classes.listGrid}>
                                <Grid container spacing={2}>
                                    {ItemState.items.map((item) => {
                                        return (
                                            <Slide in={animation} direction='up'
                                                timeout={500}
                                                mountOnEnter unmountOnExit>
                                                <Grid item sm={6} md={4}>
                                                    <Card className={classes.cardGrid} elevation={0}>
                                                        <CardMedia

                                                            classes={{ root: classes.cardMediaRoot }}
                                                            children={
                                                                <div className={classes.cardMediaImg}>
                                                                    <img src={item.photo} alt='item' />
                                                                </div>
                                                            }


                                                        />
                                                        <CardContent className={classes.cardContent}
                                                            style={{ padding: '0 16px' }}
                                                        >
                                                            <Typography variant='subtitle1' component='h2' gutterBottom style={{ fontWeight: 'bold' }}>
                                                                {
                                                                    item.twoPrice ? item.name :
                                                                        item.variationName}
                                                            </Typography>

                                                            <Typography color='textSecondary' >
                                                                {item.description || ''}
                                                            </Typography>
                                                        </CardContent>
                                                        <CardActions style={{ justifyContent: 'space-between' }}>
                                                            <Typography variant='body2'
                                                                style={{ fontWeight: 'bold', color: Colors.primary }}
                                                            >
                                                                {item.twoPrice ?
                                                                    <b>{item.price} MMK</b> :
                                                                    (item.stockQty > 0 && item.price > 0) ?
                                                                        <b>{item.price}MMK</b> :
                                                                        <i style={{ color: 'rebeccapurple' }}>out of stock</i>}
                                                            </Typography>
                                                            <IconButton onClick={() =>
                                                                item.twoPrice ?
                                                                    // dispatch(addTwoPriceItem(item._id)) :
                                                                    goToDetail(item._id) :
                                                                    (item.stockQty > 0 && item.price > 0) ?
                                                                        addToCartHandler(item) : {}
                                                            }>
                                                                {
                                                                    item.twoPrice ? <ShopTwoIcon /> :
                                                                        !(item.price > 0 && item.stockQty > 0) ? <RemoveShoppingCartIcon /> :
                                                                            loadingIndex === item.key ? <CircularProgress size={18} style={{ color: Colors.primary }} /> :
                                                                                < AddCircleOutlineOutlinedIcon />
                                                                }
                                                            </IconButton>
                                                        </CardActions>
                                                    </Card>
                                                </Grid>
                                            </Slide>
                                        )
                                    })}

                                </Grid>
                            </div>
                    }
                    {
                        ItemState.items.length < 1 ?
                            <div className={classes.mobileListGrid}><EmptyItem /></div> :
                            <div className={classes.mobileListGrid}>
                                {
                                    ItemState.items.map((item, key) => {
                                        return (
                                            <Slide in={animation} direction='up' timeout={500}>
                                                <Paper className={clsx(classes.mobileListPaper, click && (key === currentItem) && classes.clickPaper)}>
                                                    <div className={click && (key === currentItem) ? classes.setImg : classes.mobileListImg} onClick={() => { handleClick(key) }}>
                                                        <img src={item.photo} alt='coffee' />
                                                    </div>
                                                    <div className={clsx(classes.mobileListContent, click && (key === currentItem) && classes.setContent)}>
                                                        <Typography variant='subtitle1' gutterBottom style={{ fontWeight: 'bold' }}>{item.name}</Typography>
                                                        {((item.name !== item.variationName) && !item.twoPrice) &&
                                                            <Typography variant='subtitle1' gutterBottom >{item.variationName}</Typography>
                                                        }
                                                        <Typography variant='subtitle2' gutterBottom style={{ display: click && (key === currentItem) && (item.description.length > 0) ? 'block' : 'none' }} >{item.description}</Typography>
                                                        <Typography variant='subtitle2' style={{ display: (click && (key === currentItem)) ? 'none' : 'block', color: Colors.primary }}>  {item.twoPrice ?
                                                            <b>{item.price} MMK</b> :
                                                            (item.stockQty > 0 && item.price > 0) ?
                                                                <b>{item.price}MMK</b> :
                                                                <i style={{ color: 'rebeccapurple' }}>out of stock</i>}</Typography>
                                                    </div>
                                                    <div className={click && (key === currentItem) ? classes.setAction : classes.mobileListAction}>
                                                        <Typography variant='subtitle2' style={{ display: (click && (key === currentItem)) ? 'block' : 'none', color: Colors.primary }}>{item.price} MMK</Typography>
                                                        <IconButton
                                                            onClick={() => item.twoPrice ?
                                                                dispatch(addTwoPriceItem(item._id)) :
                                                                addToCartHandler(item)}>
                                                            {
                                                                item.twoPrice ? <ShopTwoIcon /> :
                                                                    !(item.price > 0 && item.stockQty > 0) ? <RemoveShoppingCartIcon /> :
                                                                        loadingIndex === item.key ? <CircularProgress size={18} style={{ color: Colors.primary }} /> :
                                                                            < AddCircleOutlineOutlinedIcon />
                                                            }
                                                        </IconButton>
                                                    </div>
                                                </Paper>
                                            </Slide>
                                        )
                                    })
                                }


                            </div>
                    }

                </div>
            </main>

        </div >

    )
}