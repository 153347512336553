import * as React from 'react';
import { makeStyles, Grid, Typography, List, ListItem } from '@material-ui/core';
import { AppState } from "../../store";
import { useSelector } from "react-redux";
import { Colors } from "../../colors";
import {
  ListItemText, Divider,
  TextField, Button,
  ListItemSecondaryAction, InputAdornment,
  TextareaAutosize, Chip,
  CircularProgress
} from '@material-ui/core';
import PointPay from "./PointPay";
import { computeCheckoutItems } from "./func";
import { isMobile } from "../../util";
const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  secondListItem: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',

    },
  }
}));
const mobile = isMobile()
interface ExchangePointSystem {
  exchangeRate: number;
  displayName: string;
  businessName: string;
  point: number;
}
interface Props {
  shippingFee: number;
  couponAmount: number;
  noteInput: (v: string) => any,
  couponInput: (v: string) => any,
  getCoupon: () => any,
  error: string | null,
  pointInput: (n: number) => any,
  exchangePointSystem?: ExchangePointSystem
}
export default function R(props: Props) {
  const classes = useStyles();
  const { CartState, AuthState } = useSelector(state => state) as AppState
  const [loading, setLoading] = React.useState(false)
  const [pointAmount, setPointAmount] = React.useState(0)
  const result = computeCheckoutItems(CartState.items)
  const getCouponHandler = () => {
    setLoading(true)
    props.getCoupon()
    setTimeout(() => {
      setLoading(false)
    }, 4000)
  }
  const pointInputHandler = (n: number) => {
    if (props.exchangePointSystem) {
      setPointAmount(n * props.exchangePointSystem.exchangeRate)
      props.pointInput(n)
    }
  }
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Summary
      </Typography>
      <List disablePadding>
        {CartState.items.map((item) => (
          <ListItem className={classes.listItem} key={item.name}>
            <ListItemText primary={`${item.variationName} (${item.qty} x ${item.price})`} />
            <Typography variant="body2">{item.price * (item.qty || 0)} MMK</Typography>
          </ListItem>
        ))}
        <Divider />

        <ListItem className={classes.listItem}>
          <ListItemText primary="Taxes" />
          <Typography variant="subtitle1" className={classes.total}>
            {result.totalAddedTaxAmount} MMK
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary="Shipping Fee" />
          <Typography variant="subtitle1" className={classes.total}>
            {props.shippingFee} MMK
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary="Discount Amount" />
          <Typography variant="subtitle1" className={classes.total}>
            <Chip label={`${props.couponAmount || 0} MMK`}
              style={{ backgroundColor: '#07b595' }}
            />
          </Typography>
        </ListItem>
        {
          props.exchangePointSystem &&
          <ListItem className={classes.listItem}>
            <ListItemText primary="Point Amount" />
            <Typography variant="subtitle1" className={classes.total}>
              <Chip label={`${pointAmount || 0} MMK`}
                style={{ backgroundColor: Colors.primary }}
              />
            </Typography>
          </ListItem>
        }
        <Divider orientation='horizontal' />
        <ListItem className={classes.listItem}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" className={classes.total}>
            {result.totalNetAmount + props.shippingFee - (props.couponAmount || 0) - pointAmount} MMK
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary="Payment" />
          <Typography variant="subtitle1" className={classes.total}>
            <Chip label={CartState.payment === 'cash' ? 'Cash on delivery' : 'Bank transfer'}
              style={{ backgroundColor: Colors.primary }}
            />
          </Typography>
        </ListItem>
        {props.error &&
          <ListItem>
            <ListItemSecondaryAction>
              <span style={{ color: 'red' }}>{props.error}</span>
            </ListItemSecondaryAction>
          </ListItem>
        }
        <ListItem className={classes.secondListItem} style={{ flexDirection: mobile ? 'column' : 'row', justifyContent: mobile ? 'flex-start' : 'space-between', alignItems: mobile ? 'flex-start' : 'center' }}>
          <TextareaAutosize
            aria-label="minimum height"
            rowsMin={3}
            placeholder="Enter notes"
            style={{ borderColor: 'white', backgroundColor: '#f5f5f5', marginBottom: 10, width: mobile ? '100%' : 'auto' }}
            onChange={(e) => props.noteInput(e.target.value)}
          />
          <Typography className={classes.total}>
            <TextField
              label='Enter Coupon Code'
              variant='filled'
              InputProps={
                {
                  endAdornment:
                    <InputAdornment position="end">
                      {
                        loading ? <CircularProgress size={23} style={{ color: 'black' }} /> :
                          <Button size='small'
                            style={{ color: '', backgroundColor: Colors.primary }}
                            onClick={() => getCouponHandler()}>
                            Apply
                        </Button>
                      }
                    </InputAdornment>
                }
              }
              onChange={(e) => props.couponInput(e.target.value)}
            />
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={mobile ? 2 : 10}>
        <Grid item xs={12} sm={5}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Shipping
          </Typography>
          <Typography gutterBottom>{Object.values(AuthState.status === 'loggedIn' ? AuthState.user : { name: '', phone: '' }).join(',')}</Typography>
          <Typography gutterBottom>{Object.values(CartState.shipping || { address: '', city: '' }).join(',')}</Typography>
        </Grid>
        {
          props.exchangePointSystem &&
          <Grid item xs={12} sm={6}>
            <PointPay pointInput={pointInputHandler} pointSystem={props.exchangePointSystem} />
          </Grid>
        }
      </Grid>
    </React.Fragment >
  );
}