import * as React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../store";
import { Iphone5 } from '../util';
import clsx from 'clsx';
import emptyImg from '../img/empty.jpg';
import { Colors } from "../colors";
import { cartToogleAction, removeFromCart, addToCart, menuToogleAction, addCategory } from "../redux";
import {
    Avatar,
    IconButton,
    Typography,
    Paper,
    Button,
    Drawer,
    Slide,
    makeStyles
} from "@material-ui/core";
import {
    ShoppingCart as ShoppingCartIcon,
    Clear as ClearIcon,
    Add as AddIcon,
    Remove as RemoveIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
} from '@material-ui/icons'
import ReactTransitionGroup from "react-addons-css-transition-group";
const useStyles = makeStyles((theme) => ({

    //--------------------New edit drawers Css---------------//
    hide: {
        display: 'none'
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        background: Colors.primary,
        margin: '0 auto',
        marginBottom: 10

    },
    menuItemContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        margin: '0 auto',

        [theme.breakpoints.down('md')]: {
            width: '68%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '87%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '78%'
        },


    },
    menuItem: {
        width: 133,
        height: 120,
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        background: 'white',
        borderRadius: 5,
        overflow: 'hidden',

    },
    cartDrawer: {
        width: 400
    },
    cartDrawerPaper: {
        width: 400,
        height: 'calc(100% - 60px)',
        zIndex: theme.zIndex.drawer + 2,
        background: "#f5f5f5",
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }

    },
    cartHeader: {
        display: 'flex',
        background: Colors.primary,
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        alignItems: 'center',
        padding: theme.spacing(1, 0),
        boxSizing: 'border-box',
    },
    cartContent: {
        padding: theme.spacing(10, 1),

    },
    cartContentItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        background: 'white',
        borderRadius: 7,
        // height: 100,
        marginTop: theme.spacing(1)
    },

    cartFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 30,
        position: 'fixed',
        bottom: 85,
        right: 5,
        width: 390,
        padding: 5,
        zIndex: theme.zIndex.drawer + 3,
        [theme.breakpoints.down('xs')]: {
            width: '96%',

        }

    },
    total: {
        padding: 4,
        marginRight: 5,
        background: '#ffffff',
        borderRadius: 16,

    },
    menuDrawer: {
        flexShrink: 0,

    },
    menuDrawerPaper: {
        background: '#f5f5f5',
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
            width: '42%',
            paddingTop: theme.spacing(13),
        },

        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    iphone5Width: {
        width: '90%',

    },
    mobileMenuGrid: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3)

    },
    emptyCart: {
        width: '100%',
        height: '100%',
        background: 'white',
        position: 'relative',
    },
    emptyBagContainer: {
        textAlign: 'center',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
    },
    cartCounterContainer: {
        width: 30,
        maxWidth: '20%',
        // height: 80,
        maxHeight: '80%',
        marginRight: 8,
        marginLeft: 4,
    },
    cartCounter: {
        borderRadius: 14,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 30,
        height: 80,
        maxWidth: 100,
        maxHeight: 100,
        background: '#f5f5f5',
        '& > span': {
            cursor: 'pointer'
        }
    },
    itemPhoto: {
        width: '100%',
        // height: 60,
        maxWidth: '13%',
        maxHeight: '80%',
        '& >img': {
            width: '100%',
            borderRadius: '50%',
            // height: 50,
            maxWidth: '100%',
            maxHeight: '100%',

        }
    },
    removeBtn: {
        width: 50,
        maxWidth: '14%',
    }
}))

export default function D() {
    const history = useHistory()
    const dispatch = useDispatch()
    const { UtilState, CategoryState, CartState, ItemState } = useSelector(state => state) as AppState
    const classes = useStyles()
    const handleCartDrawerClose = () => {
        dispatch(cartToogleAction())
    }
    const menuDrawerHandler = (catId: string) => {
        dispatch(addCategory(catId))
        dispatch(menuToogleAction())
        history.push('/')
    }
    const checkoutHandler = () => {
        dispatch(cartToogleAction())
        history.push('/checkout')
    }
    const computeTotal = () => {
        let total = 0
        CartState.items.map(i => total = total + ((i.qty || 0) * i.price))
        return total
    }
    return (
        <>
            <Drawer
                className={classes.menuDrawer}
                anchor='left'
                variant="persistent"
                open={UtilState.menuToogleAction}
                classes={{
                    paper: classes.menuDrawerPaper
                }}
            >
                <div className={classes.mobileMenuGrid}>
                    <div className={clsx(classes.menuItemContainer, Iphone5() && classes.iphone5Width)}>
                        {CategoryState.categories.map(c => {
                            return (
                                <Slide in={UtilState.menuToogleAction} direction='right' timeout={500}>
                                    <div className={classes.menuItem} style={{
                                        marginLeft: 0, height: 135, border: ItemState.categoryId === c.id ? '1px solid gray' : 'none',
                                        borderRadius: ItemState.categoryId === c.id ? '7px' : 'none'
                                    }} onClick={() => menuDrawerHandler(c.id)}>
                                        <Avatar style={{ margin: '0 auto' }} className={classes.small}>
                                            {c.name.charAt(0).toLocaleUpperCase()}</Avatar>
                                        <Typography variant='subtitle2' align='center'>{c.name}</Typography>
                                    </div>
                                </Slide>
                            )
                        })}

                    </div>
                </div>
            </Drawer>
            <Drawer
                className={classes.cartDrawer}
                anchor='right'
                variant='persistent'
                open={UtilState.cartToogleAction}
                classes={{
                    paper: classes.cartDrawerPaper
                }}
            >
                <div className={classes.cartHeader}>
                    <IconButton onClick={() => handleCartDrawerClose()}>
                        <ArrowForwardIosIcon style={{ color: '#ffffff' }} />
                    </IconButton>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <ShoppingCartIcon style={{ width: '10%', color: '#ffffff' }} />
                        <p style={{ color: '#ffffff' }}>{CartState.items.length} items</p>
                    </div>
                </div>
                {
                    CartState.items.length < 1 ? <div className={classes.emptyCart}>
                        <div className={classes.emptyBagContainer}>
                            <img alt='img' src={emptyImg} />
                        No products found
                        </div>
                    </div> :
                        <div className={classes.cartContent}>
                            <ReactTransitionGroup
                                transitionName='fade'
                                transitionEnterTimeout={500}
                                transitionLeaveTimeout={300}
                            >


                                {
                                    CartState.items.map((item) => {
                                        return (
                                            <Paper className={classes.cartContentItem}>
                                                <div className={classes.cartCounterContainer}>
                                                    <div className={classes.cartCounter}>
                                                        <span style={{ display: 'block' }}
                                                            onClick={() => dispatch(addToCart({ ...item, qty: item.qty ? (item.qty + 1) > item.stockQty ? item.qty : item.qty + 1 : 1 }))}
                                                        >
                                                            <AddIcon style={{ color: 'green' }} />
                                                        </span>
                                                        <span style={{ display: 'block', cursor: 'text', color: 'black' }}>
                                                            {item.qty || 0}
                                                        </span>
                                                        <span style={{ display: 'block' }}
                                                            onClick={() => dispatch(addToCart({ ...item, qty: item.qty ? (item.qty <= 1) ? 1 : item.qty - 1 : 1 }))}
                                                        >
                                                            <RemoveIcon style={{ color: 'red' }} />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className={classes.itemPhoto}>
                                                    <img alt='img' src={item.photo} />
                                                </div>
                                                <div style={{ textAlign: 'center' }}>
                                                    <p style={{ color: 'black', fontWeight: 'bold' }}>{item.name}</p>
                                                    <p style={{ color: '#808080', marginBottom: 1, marginTop: 0 }}>{item.variationName}</p>
                                                    <p style={{ fontWeight: 'bold', color: Colors.primary, marginTop: 0 }}>{item.price} MMK</p>
                                                </div>
                                                <p style={{ fontWeight: 'bold', color: Colors.primary }}>{item.totalAmount}MMK</p>
                                                <IconButton onClick={() => dispatch(removeFromCart(item.key))} className={classes.removeBtn}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </Paper>
                                        )

                                    })
                                }
                            </ReactTransitionGroup>
                        </div>
                }

            </Drawer>
            <Slide in={UtilState.cartToogleAction} direction='left' mountOnEnter unmountOnExit>
                <Button
                    style={{ background: Colors.primary }}
                    className={UtilState.cartToogleAction ? classes.cartFooter : classes.hide}
                    disabled={CartState.items.length < 1 ? true : false}
                    onClick={() => checkoutHandler()}>
                    <Typography variant='subtitle2' component='p' style={{ color: 'white', paddingLeft: 30 }}>
                        Checkout
              </Typography>
                    <div className={classes.total}>
                        <Typography variant='subtitle2'
                            style={{ color: Colors.primary }}>
                            {computeTotal()} MMK
                        </Typography>
                    </div>
                </Button>
            </Slide>

        </>
    )
}