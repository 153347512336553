
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Slider from '@material-ui/core/Slider';
import { Avatar } from '@material-ui/core';
import { Colors } from "../../colors";
const useStyles = makeStyles((theme) => ({
    root: {
        width: 300,
        backgroundColor: '#f5f5f5',
        border: 'none',
        maxWidth: 300,
    },
    margin: {
        height: theme.spacing(3),
    },
    media: {
        height: 120,
    },
    large: {
        backgroundColor: 'white',
        color: 'black',
        border: `2px solid ${Colors.primary}`,
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    displayName: {
        textAlign: 'center',
        display: 'block'
    },
    pointAndMoneyMain: {
        display: 'flex', width: '100%', height: '100%',
    },
    pointAvatar: {
        color: 'black', margin: 'left', display: 'block', textAlign: 'center'
    },
    equalAvatar: {
        color: 'black',
        marginLeft: '50px',
        textAlign: 'center',
        justifyContent: 'center'
    },
    moneyAvatar: {
        marginLeft: 'auto', textAlign: 'center'
    }
}));
interface PointSystem {
    exchangeRate: number;
    displayName: string;
    businessName: string;
    point: number;
}
interface Props {
    pointInput: (n: number) => any,
    pointSystem: PointSystem
}
export default function PointPay(props: Props) {
    const classes = useStyles();
    const [point, setPoint] = useState(0)
    const onChangeHandler = (v: any) => {
        props.pointInput(v)
        setPoint(v)
    }
    const incrementPoint = () => {
        const v = point + 1
        props.pointInput(v)
        setPoint(v)
    }
    const decrementPoint = () => {
        const v = point > 0 ? point - 1 : 0
        props.pointInput(v)
        setPoint(v)
    }
    return (
        <Card className={classes.root}>
            <CardContent style={{ width: '100%' }}>
                <div style={{ display: 'block' }}>

                    <div className={classes.displayName}>
                        <b>{props.pointSystem.businessName} </b>
                        <i>({props.pointSystem.displayName})</i>
                    </div>

                    <div className={classes.pointAndMoneyMain}>
                        <div className={classes.pointAvatar}>
                            <Avatar variant='circle' className={classes.large} >
                                <div style={{ display: 'block' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: 14 }}>
                                        {point}
                                    </div>
                                    <div style={{ fontSize: 12 }}>
                                        points
                            </div>
                                </div>

                            </Avatar>
                        </div>
                        <div className={classes.equalAvatar}>
                            <Avatar variant='circle' style={{ backgroundColor: 'white', color: 'black', marginTop: 20 }} >=</Avatar>
                        </div>
                        <div className={classes.moneyAvatar}>
                            <Avatar variant='circle' className={classes.large} >
                                <div style={{ display: 'block' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: 14 }}>
                                        {point * props.pointSystem.exchangeRate}
                                    </div>
                                    <div style={{ fontSize: 12, }}>
                                        MMK
                            </div>
                                </div>
                            </Avatar>
                        </div>
                    </div>
                    <div style={{ padding: 0 }}>
                        <b style={{ fontSize: 12, fontWeight: 'bold', color: Colors.primary, textAlign: 'center' }}>
                            You have <i style={{ color: 'black', fontSize: 14 }}>{props.pointSystem.point}</i> points and you can change your points to money.<b style={{ color: 'black' }}> ( 1 point = {props.pointSystem.exchangeRate} MMK)</b>
                        </b>
                    </div>
                </div>
            </CardContent>
            <CardActions style={{ padding: 2 }}>
                <IconButton component="span" style={{ color: Colors.primary, }} onClick={() => decrementPoint()}>
                    <RemoveIcon />
                </IconButton>
                <div className={classes.root}>
                    <Slider
                        defaultValue={point}
                        aria-labelledby="discrete-slider-always"
                        max={props.pointSystem.point}
                        value={point}
                        onChange={(e, v) => onChangeHandler(v)}
                        valueLabelDisplay="on"
                        style={{ color: Colors.primary }}
                    />
                </div>
                <IconButton component="span" style={{ color: Colors.primary, }} onClick={() => incrementPoint()}>
                    <AddIcon />
                </IconButton>
            </CardActions>

        </Card>
    );
}
