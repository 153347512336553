import firebase from 'firebase/app';
import config from '../../config'
import { Checkout, CouponRequest, CheckoutItem, CheckoutCartItem, Merchant } from "../../store";
export const checkout = async (data: Checkout) => {
    const checkoutfunc = firebase.functions().httpsCallable('posCheckoutWeb');
    const result = await checkoutfunc({ ...data }) as any
    const { data: d } = result as { data: { success: boolean, message: string, data: any } }
    if (d.success) {
        return {
            success: true,
        }
    }
    return { success: false, message: d.message }
}
export const checkout2 = async (data: Checkout) => {
    const checkoutfunc = firebase.functions().httpsCallable('posCheckoutWeb2');
    const result = await checkoutfunc({ ...data }) as any
    console.log('checkout 2 response', result)
    const { data: d } = result as { data: { status: 'invalid' | 'success', message: string, data: any, code: string } }
    if (d.status === 'success') {
        return {
            success: true,
        }
    }
    return { success: false, message: d.message, code: d.code }
}
export const getCoupon = async (data: CouponRequest) => {
    const couponFunc = firebase.functions().httpsCallable('getCoupon')
    const result = await couponFunc({ ...data }) as any
    const { data: d } = result as { data: { status: string, message: string, data: { unit: 'percentage' | 'value', value: number } } }
    if (d.status === "success") {
        return { success: true, unit: d.data.unit, value: d.data.value }
    }
    return {
        success: false,
        message: d.message
    }
}
export const givePoint = (m: Merchant, amount: number) => {
    if (m.posPointSystem === 'whole') {
        if (m.pointSystem?.exchangeRate) {
            return Math.round(amount / m.pointSystem?.exchangeRate)
        }
    }
    return 0;
}
export const computeCheckoutItems = (data: CheckoutCartItem[] = []) => {
    let totalAmount = 0,
        totalAddedTaxAmount = 0,
        totalNetAmount = 0,
        taxIds: string[] = [],
        totalEarnPoints = 0
    const _items = data.map((i, index) => {
        const { unit } = i
        totalAmount += i.totalAmount
        totalAddedTaxAmount += i.addedTaxAmount
        totalNetAmount += i.totalNetAmount
        if (Array.isArray(i.applicableTaxIds)) {
            taxIds = [...i.applicableTaxIds]
        }
        let itemPoint = 0
        if (i.unitPoints && i.qty) {
            itemPoint = i.qty * i.unitPoints
        }
        totalEarnPoints = totalEarnPoints + itemPoint
        //toal amount => total amount value without taxes (qty * price)
        return {
            addedTaxAmount: 0,
            name: i.name,
            unitPoints: itemPoint,
            optionName: i.variationName,
            itemVariationId: i.variationId,
            itemId: i._id,
            price: i.price,
            photo: i.photo,
            netAmount: i.totalAmount,
            currency: 'MMK',
            inclusiveTaxAmount: 0,
            index: index,
            totalNetAmount: i.totalAmount,
            totalAmount: i.totalAmount,
            taxIds: i.applicableTaxIds || [],
            qty: { value: i.qty, precision: unit.precision, suffix: "" }
        } as CheckoutItem
    })

    return {
        totalAmount,
        totalAddedTaxAmount,
        totalNetAmount,
        taxIds,
        totalEarnPoints,
        items: _items,
    }
}


export const getMemberPointAndExchangeRate = async (phone: string) => {
    //getGrouponMember
    const merchantId = config.merchantId
    const func = firebase.functions().httpsCallable('getGrouponMember');
    const result = await func({ merchantId, phone }) as any
    const { data: res } = result as { data: { status: string, message: string, data: any } }
    console.log('response point', res)
    if (res.status === 'success') {
        const d = res.data as {
            groupon: { exchangePointSystem: { exchangeRate: number }, pointSystem: { exchangeRate: number } },
            member: { pointSystem: { point: number }, businessName: string, displayName?: string }
        }
        return {
            success: true,
            data: {
                point: d.member.pointSystem.point,
                exchangeRate: d.groupon.exchangePointSystem.exchangeRate,
                groupPointSystem: d.groupon.pointSystem,
                businessName: d.member.businessName,
                displayName: d.member.displayName || phone
            }
        }
    }
    return { success: false, message: res.message }
}